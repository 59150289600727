import * as React from "react";
import { ContactForm } from "./ContactForm";
import { ContactInfo } from "./ContactInfo";

export const Contact = () => {
  return (
    <div className="w-full">
      <div className="container mx-auto mb-16">
        <div className="flex flex-col lg:flex-row">
          <ContactForm />
          <ContactInfo />
        </div>
      </div>
    </div>
  );
};
