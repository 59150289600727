import * as React from "react";
import { Layout } from "../components/global/Layout";
import { Contact } from "../components/contact/Contact";
import SEO from "../components/global/SEO";
import { graphql } from "gatsby";

const ContactPage = () => {
  return (
    <Layout>
      <SEO />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
