import * as React from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";
import { TextArea } from "./TextArea";
import { TextInput } from "./TextInput";
import { Alert } from "./Alert";
import { FormStatus } from "./constants";
import { SpinLoader } from "../../reusables/Mixed";

const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Sorry, name you provided is too short")
    .max(50, "Sorry, name you provided is too long")
    .required("Please, tell us your name"),
  email: Yup.string()
    .email("Sorry, email format isn't right")
    .required("Please, provide us with your email address"),
  message: Yup.string()
    .min(100, "Sorry, your question is too short")
    .max(5000, "Sorry, your question is too long")
    .required("Please, tell us your question"),
});

export const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formStatus, setFormStatus] = React.useState(FormStatus.initial);

  const alertContainer = React.useRef(null);

  React.useEffect(() => {
    if (formStatus === FormStatus.success) {
      setTimeout(() => {
        setFormStatus(FormStatus.initial);
      }, 5000);
    }
  }, [formStatus]);

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const handleKeyUp = () => setFormStatus(FormStatus.initial);

  const handleSubmit = async (values, onSubmitProps) => {
    if (!executeRecaptcha) {
      console.log("Failed recaptcha check");
      setFormStatus(FormStatus.failed);
      return;
    }

    const { resetForm } = onSubmitProps;
    setFormStatus(FormStatus.sending);

    try {
      const response = await axios.post("/.netlify/functions/contact", values);
      if (response.status === 200 && response.data.status === "success") {
        setFormStatus(FormStatus.success);
        resetForm();
      } else {
        setFormStatus(FormStatus.failed);
      }
      alertContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } catch (error) {
      console.log(error);
      setFormStatus(FormStatus.failed);
    }
  };

  return (
    <div className="m-6 lg:m-10 lg:basis-7/12">
      <div className="flex flex-col items-center justify-center mb-6 lg:justify-start lg:items-start">
        <span className="text-2xl font-bold mb-5">Have a question?</span>
        <span className="text-base text-center lg:text-left">
          Pop us a message and we'll get in touch
        </span>
      </div>
      <div ref={alertContainer}>
        <Alert formStatus={formStatus} />
      </div>
      <div className="flex items-center justify-center ">
        <Formik
          initialValues={initialValues}
          validationSchema={ContactFormSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            const { errors, touched, isValid, dirty, values } = formikProps;
            return (
              <Form className="w-full">
                <div className="space-y-10">
                  <TextInput
                    label="Your Name"
                    name="name"
                    onKeyUp={handleKeyUp}
                    required
                    {...formikProps}
                  />
                  <TextInput
                    label="Your Email Address"
                    name="email"
                    type="email"
                    onKeyUp={handleKeyUp}
                    required
                    {...formikProps}
                  />
                  <TextArea
                    label="Your question"
                    name="message"
                    onKeyUp={handleKeyUp}
                    required
                    {...formikProps}
                  />
                </div>

                <button
                  type="submit"
                  disabled={!(isValid && dirty)}
                  className="btn btn-md mt-7 flex items-center disabled:bg-gray-300"
                >
                  {formStatus === FormStatus.sending && <SpinLoader />}
                  send{formStatus === FormStatus.sending ? "ing" : ""} message
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
